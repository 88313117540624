import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1080.000000 608.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g transform="translate(0.000000,608.000000) scale(0.100000,-0.100000)"
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd">
<path d="M0 3040 l0 -3040 5400 0 5400 0 0 3040 0 3040 -5400 0 -5400 0 0
-3040z m5622 1995 c367 -43 691 -168 988 -383 198 -143 442 -410 560 -613 185
-316 271 -609 296 -1009 10 -161 -22 -428 -73 -607 l-18 -63 -166 0 -166 0 32
108 c102 334 99 702 -6 1031 -65 203 -142 353 -270 525 -256 345 -634 585
-1069 677 -90 20 -136 23 -330 23 -196 1 -239 -2 -337 -22 -326 -66 -639 -227
-872 -448 -260 -247 -423 -528 -510 -879 -35 -139 -44 -247 -39 -455 6 -204
27 -339 77 -484 12 -33 21 -63 21 -68 0 -5 -75 -7 -167 -6 l-167 3 -27 112
c-115 475 -64 968 145 1398 107 221 211 370 385 548 328 336 757 552 1206 606
50 6 101 13 115 15 65 9 278 4 392 -9z m477 -670 c89 -47 152 -103 212 -188
71 -101 99 -197 99 -336 0 -119 -10 -145 -51 -139 -24 3 -24 4 -24 108 -1 70
-7 125 -19 165 -42 143 -137 256 -272 325 -70 35 -86 58 -64 85 19 23 46 18
119 -20z m-73 -174 c70 -41 148 -129 175 -199 26 -66 37 -189 20 -221 -13 -23
-55 -29 -67 -10 -4 7 -8 44 -9 83 -3 122 -50 204 -157 273 -68 44 -81 66 -56
91 18 18 38 15 94 -17z m-34 -198 c73 -76 79 -221 9 -198 -12 3 -23 17 -26 31
-3 13 -7 36 -10 50 -3 14 -26 47 -50 73 -25 27 -45 53 -45 59 0 6 10 18 21 26
19 13 26 13 48 1 14 -7 38 -26 53 -42z m-489 -25 c101 -160 299 -519 294 -533
-8 -19 -85 -20 -258 -4 -105 9 -123 9 -144 -5 -20 -13 -60 -16 -244 -16 l-221
0 -20 40 c-32 62 -28 70 58 119 42 24 96 55 119 68 l41 24 20 -22 20 -21 55
33 c53 31 57 36 123 173 72 150 100 196 115 196 5 0 24 -24 42 -52z m287 -60
c57 -39 75 -72 75 -137 -1 -49 -6 -65 -29 -96 l-29 -38 -24 39 c-13 22 -49 83
-80 137 -62 110 -61 117 13 117 26 0 53 -8 74 -22z m-1025 -335 c-14 -37 -17
-83 -7 -112 l11 -31 -69 0 -70 0 -2 -487 -3 -488 -77 -3 -78 -3 0 546 0 546
25 24 c23 24 30 25 151 25 116 0 126 -1 119 -17z m1466 -2 l24 -19 3 -551 2
-551 -80 0 -80 0 0 490 0 490 -79 0 c-82 0 -91 5 -91 48 0 10 -11 38 -25 65
l-25 47 164 0 c146 0 166 -2 187 -19z m-463 -300 c-1 -4 -90 -83 -196 -175
l-194 -166 -28 22 c-64 53 -370 320 -370 324 0 2 178 4 396 4 218 0 394 -4
392 -9z m-752 -178 c68 -59 124 -110 124 -114 0 -8 -248 -289 -255 -289 -3 0
-5 115 -5 255 0 140 3 255 6 255 3 0 62 -48 130 -107z m852 -274 l-3 -132
-125 143 c-69 78 -125 145 -125 149 0 4 56 56 125 116 l125 108 3 -126 c1 -69
1 -185 0 -258z m-568 26 c36 -30 70 -55 77 -55 6 0 42 25 78 56 l67 57 24 -24
c13 -13 75 -82 138 -154 l114 -130 -416 -3 c-228 -1 -417 0 -419 2 -5 5 259
306 267 306 3 0 35 -25 70 -55z m-465 -585 c600 -6 1583 -15 2184 -20 602 -6
1098 -13 1102 -17 3 -5 12 -143 18 -308 7 -165 16 -397 21 -515 5 -118 7 -217
4 -219 -2 -2 -700 1 -1551 8 -852 7 -2081 16 -2732 21 -651 5 -1186 11 -1189
15 -3 3 -7 38 -9 78 -2 39 -11 200 -19 357 -22 392 -31 603 -26 614 3 5 245 6
556 2 303 -3 1042 -11 1641 -16z"/>
<path d="M7260 2143 c-102 -1 -810 -7 -1575 -13 -1612 -13 -2865 -27 -2870
-32 -4 -4 -23 -273 -31 -438 -4 -74 -9 -186 -13 -249 l-6 -114 885 7 c1891 15
3543 26 3930 26 223 0 410 4 417 8 10 6 34 390 45 727 l3 80 -300 0 c-165 0
-383 -1 -485 -2z m-2196 -173 c20 -7 26 -17 26 -38 0 -36 -17 -52 -55 -52 -38
0 -55 16 -55 52 0 41 35 57 84 38z m197 0 c19 -11 25 -45 11 -67 -4 -7 -22
-15 -39 -19 -37 -7 -77 24 -66 53 15 43 51 56 94 33z m-2000 -47 c59 -26 89
-74 96 -151 7 -78 -11 -138 -56 -183 -45 -46 -91 -59 -203 -59 l-88 0 0 203
c0 112 3 207 8 211 15 16 195 1 243 -21z m3174 16 c91 -25 135 -89 135 -194 0
-72 -24 -130 -69 -168 -43 -36 -69 -42 -181 -42 l-95 0 -3 195 c-1 107 0 200
2 207 7 17 154 18 211 2z m517 -194 c36 -110 67 -203 67 -207 1 -5 -26 -8 -59
-8 -57 0 -60 1 -66 28 -12 54 -10 53 -81 50 -66 -3 -66 -3 -78 -38 -11 -34
-13 -35 -68 -38 -32 -2 -57 1 -57 6 0 5 6 28 14 53 8 24 35 109 61 189 25 80
48 151 50 158 4 9 25 12 77 10 l73 -3 67 -200z m760 0 c37 -110 67 -203 68
-207 0 -5 -27 -8 -59 -8 l-59 0 -9 38 -9 37 -69 0 -70 0 -9 -37 -10 -38 -58 0
c-32 0 -58 4 -58 9 0 7 62 203 125 399 4 9 25 12 77 10 l73 -3 67 -200z
m-3420 123 l3 -73 64 0 64 0 -2 73 -2 73 58 -3 58 -3 0 -200 0 -200 -55 0 -55
0 -1 80 -2 80 -63 0 -64 0 -3 -82 -3 -83 -54 0 -55 0 0 205 0 205 55 0 54 0 3
-72z m3058 27 l0 -45 -50 0 -50 0 0 -160 0 -160 -55 0 -55 0 0 160 0 160 -50
0 -50 0 0 45 0 45 155 0 155 0 0 -45z m-3718 -45 c68 -19 78 -44 78 -191 l0
-129 -35 0 c-25 0 -37 6 -45 21 -11 20 -12 20 -31 3 -12 -10 -41 -19 -75 -22
-47 -4 -58 -1 -79 18 -12 12 -26 37 -29 55 -14 74 40 125 132 125 50 0 53 2
50 23 -2 18 -9 22 -38 21 -19 0 -47 -4 -61 -8 -23 -6 -28 -3 -39 24 -16 38 -8
48 51 59 62 12 79 12 121 1z m438 -26 c24 -23 25 -31 28 -159 l4 -135 -56 0
-56 0 0 109 c0 118 -8 139 -54 127 -30 -7 -36 -29 -36 -143 l0 -93 -55 0 -55
0 0 160 0 160 40 0 c29 0 42 -5 50 -20 10 -18 11 -19 30 -2 45 38 119 37 160
-4z m752 26 c68 -19 78 -44 78 -191 l0 -129 -35 0 c-24 0 -37 6 -45 20 l-11
21 -29 -21 c-39 -27 -118 -28 -148 0 -29 27 -39 86 -21 119 20 36 75 61 132
61 44 0 48 2 45 23 -2 18 -9 22 -38 21 -19 0 -47 -4 -61 -8 -24 -7 -28 -4 -38
25 -13 38 -9 44 39 57 47 14 87 14 132 2z m1085 -1 c71 -18 83 -46 83 -196 l0
-123 -40 0 c-22 0 -40 3 -40 8 0 4 -4 13 -9 21 -7 12 -14 10 -36 -8 -37 -28
-117 -29 -145 -1 -25 25 -34 61 -26 106 10 49 53 74 129 74 59 0 74 14 42 40
-14 12 -36 10 -91 -6 -17 -4 -24 1 -34 26 -15 37 -9 46 45 59 56 13 70 13 122
0z m-817 -194 l0 -195 -29 -32 c-26 -29 -34 -32 -87 -33 l-59 0 -3 43 c-3 41
-2 42 26 42 18 0 32 6 36 16 3 9 6 92 6 185 l0 169 55 0 55 0 0 -195z m185 35
l0 -155 -52 -3 -53 -3 0 161 0 161 53 -3 52 -3 0 -155z m160 148 c2 -7 16 -53
31 -103 l28 -90 31 100 31 100 52 3 c40 2 52 0 52 -11 0 -30 -139 -380 -160
-402 -30 -32 -70 -47 -116 -43 -38 3 -39 4 -39 43 0 38 2 40 35 45 23 3 39 13
48 30 13 23 9 37 -47 179 -34 84 -61 155 -61 157 0 2 25 4 55 4 36 0 57 -5 60
-12z"/>
<path d="M3120 1741 l0 -121 25 0 c15 0 39 7 54 15 38 19 58 93 42 153 -14 49
-29 62 -81 69 l-40 6 0 -122z"/>
<path d="M6335 1850 c-3 -5 -5 -57 -4 -115 1 -118 5 -125 62 -109 82 24 90
194 11 224 -32 13 -61 13 -69 0z"/>
<path d="M6803 1830 c-6 -19 -14 -48 -18 -65 -3 -16 -8 -38 -11 -47 -5 -15 1
-18 37 -18 l42 0 -7 38 c-3 20 -12 57 -19 82 l-13 45 -11 -35z"/>
<path d="M7567 1845 c-3 -11 -11 -40 -17 -65 -6 -25 -13 -53 -16 -62 -4 -15 1
-18 34 -18 21 0 41 2 44 5 3 3 -3 36 -13 73 -26 91 -25 90 -32 67z"/>
<path d="M3531 1651 c-15 -27 9 -56 38 -46 22 7 43 43 34 58 -9 15 -62 7 -72
-12z"/>
<path d="M4721 1651 c-7 -13 -6 -24 3 -37 12 -15 18 -16 39 -7 14 7 26 22 29
37 5 24 2 26 -28 26 -22 0 -37 -6 -43 -19z"/>
<path d="M5812 1658 c-16 -16 -15 -33 3 -48 24 -20 65 3 65 35 0 21 -5 25 -28
25 -15 0 -33 -5 -40 -12z"/>
</g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
